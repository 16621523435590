import React from "react"

import "../styles/privacy-statement.css"

import Layout from "../components/layout"

const PrivactStatement = props => {
  return (
    <Layout location={props.location}>
      <div className="privacy-statement-page">
        <h1 className="privacy-statement-page__title">
          Dataunitor Privacy Statement
        </h1>
        <p className="privacy-statement-page__detail">
          This privacy statement will explain how Our Company uses the personal
          data we collect from you when you use our Website.
        </p>
        <h3 className="privacy-statement-page__sub-title">
          What data do we collect?
        </h3>
        <p className="privacy-statement-page__detail">
          Our Company collects the following data: <br />
          <br />• Personal identification information (Name, email address,
          phone number)
        </p>
        <h3 className="privacy-statement-page__sub-title">
          How do we collect your data?
        </h3>
        <p className="privacy-statement-page__detail">
          You directly provide Our Company with the data we collect. We collect
          data and process data when you:
          <br />
          <br />
          • Voluntarily complete a contact form or provide feedback via email.
          <br />
          <br />
          Our Company may also receive your data indirectly from the following
          sources:
          <br />
          <br />• None
        </p>
        <h3 className="privacy-statement-page__sub-title">
          How will we use your data?
        </h3>
        <p className="privacy-statement-page__detail">
          Our Company collects your data so that we can:
          <br />
          <br />
          • Respond to any messages via the Website contact form or email.
          <br />
          <br />
          Your data is not used for any other purposes or shared with third
          parties. It is removed upon your withdrawal of consent.
        </p>
        <h3 className="privacy-statement-page__sub-title">Marketing</h3>
        <p className="privacy-statement-page__detail">
          Our Company would like to send you information about products and
          services of ours that we think you might like. If you have agreed to
          receive marketing, you may always opt out at a later date.
          <br />
          <br />
          You have the right at any time to stop Our Company from contacting you
          for marketing purposes. If you no longer wish to be contacted for
          marketing purposes, please contact us (see How to contact us).
        </p>
        <h3 className="privacy-statement-page__sub-title">
          What are your data protection rights?
        </h3>
        <p className="privacy-statement-page__detail">
          Our Company would like to make sure you are fully aware of all of your
          data protection rights. Every user is entitled to the following:
          <br />
          <br />
          <b>The right to access</b> – You have the right to request Our Company
          for copies of your personal data. We may charge you a small fee for
          this service.
          <br />
          <br />
          <b>The right to rectification</b> – You have the right to request that
          Our Company correct any information you believe is inaccurate. You
          also have the right to request Our Company to complete the information
          you believe is incomplete.
          <br />
          <br />
          <b>The right to erasure</b> – You have the right to request that Our
          Company erase your personal data, under certain conditions.
          <br />
          <br />
          <b>The right to restrict processing</b> – You have the right to
          request that Our Company restrict the processing of your personal
          data, under certain conditions.
          <br />
          <br />
          <b>The right to object to processing</b> – You have the right to
          object to Our Company’s processing of your personal data, under
          certain conditions.
          <br />
          <br />
          <b>The right to data portability</b> – You have the right to request
          that Our Company transfer the data that we have collected to another
          organization, or directly to you, under certain conditions.
          <br />
          <br />
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us (see How
          to contact us).
        </p>
        <h3 className="privacy-statement-page__sub-title">Cookies</h3>
        <p className="privacy-statement-page__detail">
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our Website, we may collect information from you automatically
          through cookies [1] or similar technology
        </p>
        <h3 className="privacy-statement-page__sub-title">
          How do we use cookies?
        </h3>
        <p className="privacy-statement-page__detail">
          Our Company uses cookies in a range of ways to improve your experience
          on our Website, including:
          <br />
          <br />
          • Understanding how you use our Website (Performance)
          <br />
          <br />• Remember your preferences when using our Website
          (Functionality)
        </p>
        <h3 className="privacy-statement-page__sub-title">
          What types of cookies do we use?
        </h3>
        <p className="privacy-statement-page__detail">
          There are different types of cookies, however, our Website uses:
          <br />
          <br />
          • Functionality – The Website uses first-party cookies so that we
          recognize you on our Website and remember your previously selected
          preferences. These could include what, style and language you prefer.
          <br />
          <br />• Performance – The Website uses a third-party cookie, Google
          Analytics, which allows us to notice trends to improve the user
          experience on our Website. The information is processed in
          de-identified and aggregated form [2]. De-identified means that we
          cannot track the information we collect back to the individual user
          [3].
        </p>
        <h3 className="privacy-statement-page__sub-title">
          How to manage cookies?
        </h3>
        <p className="privacy-statement-page__detail">
          You can at any time set your browser not to accept cookies and remove
          cookies from your browser [4].{" "}
        </p>
        <h3 className="privacy-statement-page__sub-title">
          Privacy policies of other websites
        </h3>
        <p className="privacy-statement-page__detail">
          The Our Company website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
        </p>
        <h3 className="privacy-statement-page__sub-title">
          Changes to our privacy policy
        </h3>
        <p className="privacy-statement-page__detail">
          Our Company keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on
          April 24th, 2020.
        </p>
        <h3 className="privacy-statement-page__sub-title">How to contact us</h3>
        <p className="privacy-statement-page__detail">
          If you have any questions about Our Company’s privacy policy, the data
          we hold on you, or you would like to exercise one of your data
          protections rights, please do not hesitate to contact us. Email us at:{" "}
          <a href="mailto:info@dataunitor.com">info@dataunitor.com</a> <br />
          <br />
          <b>References</b> <br />
          [1] What are cookies? <br />
          <a href="https://www.internetcookies.org/" target="_blank">
            https://www.internetcookies.org/
          </a>{" "}
          <br />
          [2] IP Anonymization (or IP masking) in Analytics <br />
          <a
            href="https://support.google.com/analytics/answer/2763052?hl=en&ref_topic=2919631"
            target="_blank"
          >
            https://support.google.com/analytics/answer/2763052?hl=en&ref_topic=2919631
          </a>{" "}
          <br />
          [3] Data Processing Amendment to the Google Analytics Agreement <br />
          <a
            href="https://www.google.com/analytics/terms/dpa/dataprocessingamendment_20160909.html"
            target="_blank"
          >
            https://www.google.com/analytics/terms/dpa/dataprocessingamendment_20160909.html
          </a>{" "}
          <br />
          [4] How to Disable Cookies in Your Browser <br />
          <a
            href="https://www.internetcookies.org/disable-cookies/"
            target="_blank"
          >
            https://www.internetcookies.org/disable-cookies/
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default PrivactStatement
